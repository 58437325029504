import React, { useContext, useEffect, useState } from "react";
import { TableSkeleton } from "@ant-design/pro-components";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Table, Tooltip } from "antd";
import { InputField } from "../../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import {
  getEventsById,
  getRegiterEventsById,
} from "../../../../serviceApi/adminApi/EventApi";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { loginContext } from "../../../../components/context/LoginContext";
import { sendReminderByGmail } from "../../../../serviceApi/adminApi/AssessmentApi";
import xlsx from "sheetjs-style";
import * as FileSaver from "file-saver";
import Swal from "sweetalert2";
interface DataType {
  name: string | null;
  duration: number;
  id: number;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

function EventRegister() {
  const navigate = useNavigate();
  const { eventid }: any = useParams();
  const { eventId, setEventId, isShare, setIsShare }: any =
    useContext(loginContext);
  const [page, setPage] = React.useState(1);
  const [name, setName] = React.useState();
  const [modal2Open, setModal2Open] = React.useState(false);
  const [eventData, setEventData] = useState<any>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: page,
      pageSize: 5,
    },
  });

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "Event" },
  ];

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const addAssessment = (e) => {
    setEventId(eventid);
    setIsShare(false);
    navigate("/subadmin/skills/list");
  };

  const getEventRegister = async () => {
    const data = await getRegiterEventsById(eventid);
    if (data?.data?.getRegiterEventsById.length > 0) {
      const resultData = data?.data?.getRegiterEventsById;
      const newArray = resultData.map((m) => [m.userId, m]);
      const newMap = new Map(newArray);
      const iterator: any = newMap.values();
      const uniqueResult: any = [...iterator];
      setEventData(uniqueResult);
    }
  };

  const getEvent = async () => {
    const data = await getEventsById(eventid);
    if (data?.data?.getEventsById.length > 0) {
      const events = JSON.parse(data?.data?.getEventsById[0].event);
      setName(events[0].name);
    } else {
      navigate("/404");
    }
  };

  useEffect(() => {
    getEventRegister();
    getEvent();
  }, []);

  const data = eventData?.map((item, index) => {
    return {
      id: index + 1,
      userId: item?.userId,
    };
  });

  const exportData = (fileName, jsonData) => {
    const ws: any = xlsx?.utils?.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx?.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      render: (id, record, index: number) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },
    {
      title: "userId",
      dataIndex: "userId",
      //   defaultSortOrder: "userId",
      // sorter: (a, b) => a.name - b.name,
      render: (userId) => {
        return <p className="text-center">{userId}</p>;
      },
    },
  ];
  const sendReminder = async () => {
    try {
      const result = await sendReminderByGmail({
        eventId: eventid,
      });

      if (result?.data?.sendReminder) {
        setModal2Open(false);
        Swal.fire({
          icon: "success",
          title: "Invitation request has been sent",
          showConfirmButton: false,
          timer: 4000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to sent Invitation Email Something went wrong !",
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong !",
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };
  const handleShare = () => {
    setEventId(eventid);
    setIsShare(true);
    sendReminder();
  };

  return (
    <>
      <div className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <div className="bg-white lg:hidden md:hidden shadow-xl justify-center mt-32 m-3 text-center">
          <h1 className="text-lg text-black font-primary mb-2">
            Your device is not compatible for it. Please login on large device
            (e.g Tablet or laptop).
          </h1>
          <Link
            to="/subadmin"
            className="bg-brand-500 mt-2 px-10 font-primary hover:bg-brand-600 hover:text-white  text-white font-semibold p-2 rounded-md"
          >
            Back
          </Link>
        </div>
        <div className="mb-2">
          <p className="text-2xl capitalize font-primary text-black font-semibold  my-0">
            Event Register List
          </p>
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="flex flex-row justify-between relative">
          <div className="flex items-start flex-col mt-5  h-26 p-6 bg-white border border-gray-200 shadow-md w-full ">
            <div className="py-2 flex lg:flex-no-wrap flex-wrap lg:flex-row justify-between items-center w-full">
              <p className="text-lg font-primary capitalize text-brand-500 font-semibold  my-3 lg:my-0">
                {" "}
                <span className="text-black">Event Name : {name}</span>
              </p>
            </div>
            <hr className="mb-5 mt-3 w-full bg-black text-black" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 mr-1 h-4 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            <div className="flex">
              <button
                className="m-5 bg-brand rounded p-2 hover:bg-blue-500 text-white text-sm"
                onClick={(e) => addAssessment(e)}
              >
                Add an Assessment
              </button>
              <button
                onClick={() => {
                  handleShare();
                }}
                className="m-5 bg-brand rounded p-2 hover:bg-blue-500 text-white text-sm"
              >
                Send an event reminder
              </button>
              <Tooltip title="Excel">
                <button
                  className="m-5 bg-brand rounded p-2 hover:bg-blue-500 text-white text-sm"
                  onClick={(e) =>
                    exportData(`${name}-skillcef-event-users`, data)
                  }
                >
                  Export
                </button>
              </Tooltip>
            </div>
            <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
              <Table
                columns={columns}
                className="domain-request-table"
                rowKey={(record) => record.id}
                dataSource={eventData}
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                  defaultPageSize: 5,
                  hideOnSinglePage: false,
                  showSizeChanger: true,
                }}
                loading={false}
                onChange={handleTableChange}
                // className="w-full assessment-table"
              />
              <Modal
                title="Send Invitation"
                centered
                open={modal2Open}
                className="cat-modal"
                onCancel={() => setModal2Open(false)}
              >
                <InputField
                  type="email"
                  //   onChange={setEmailId}
                  placeholder="Name"
                  id="Email Id"
                  //   emailValidCheck={setEmailErr}
                />
                <span className="text-red-500 mt-2"></span>
                <div className="mt-8">
                  <PrimaryButton
                    // onclick={sendInvitation}
                    disabledStatus={status}
                    text="Send"
                    className={`${
                      status
                        ? "bg-gray-300 cursor-not-allowed border-gray-300"
                        : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                    }`}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventRegister;
