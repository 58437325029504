import React, { useState, useEffect } from "react";
import UserIcon from "../../../assets/leftnavicons/UserIcon.svg";

const SpeakersSection = ({ speakers }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isNotActive, setIsNotActive] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState<any>([]);
  const maxLength = 100; // Maximum length before truncating
  const toggleExpand = (index) => {
    setExpandedIndexes(
      (prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index) // Remove index if already expanded
          : [...prev, index] // Add index if not expanded
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint in Tailwind
    };
    handleResize(); // Set on initial load
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let interval;
    if (isNotActive) {
      interval = setInterval(() => {
        setCurrentSlide(
          (prevSlide) =>
            (prevSlide + 1) % (speakers?.length >= 3 ? 3 : speakers?.length)
        );
      }, 4000);
    }
    return () => clearInterval(interval);
  }, [speakers.length, isNotActive]);

  return (
    <div>
      {isMobile && (
        <div className="overflow-hidden relative h-full">
          <div
            className="transition-transform duration-700 ease-in-out grid grid-cols-1 gap-6 p-6"
            style={{
              transform: `translateY(-${currentSlide * 25}%)`,
            }}
          >
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white shadow-md rounded-lg p-4 w-full h-full justify-center transform transition-transform hover:scale-105 hover:shadow-lg hover:z-10"
              >
                <img
                  src={
                    speaker?.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${speaker?.image}`
                      : UserIcon
                  }
                  alt={speaker?.speakerName}
                  className="w-24 h-24 rounded-full object-cover mb-4 border-gray-500 border-x-black shadow-xl"
                />
                <h3 className="text-lg font-bold text-gray-800 text-center">
                  {speaker?.speakerName}
                </h3>
                <p
                  className="text-sm text-black truncate overflow-wrap: break-word overflow-para word-wrap: break-word break-words overflow-wrap break-word  w-full text-wrap text-left"
                  dangerouslySetInnerHTML={{
                    __html: speaker?.speakerDescription,
                  }}
                ></p>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="overflow-hidden relative h-full">
          {/* Slider Container */}
          <div
            className="transition-transform duration-700 ease-in-out grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 p-6"
            onMouseEnter={() => setIsNotActive(false)}
            onMouseLeave={() => setIsNotActive(true)}
            style={{
              transform: `translateX(-${currentSlide * 25}%)`,
            }}
          >
            {speakers.map((speaker, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white shadow-md rounded-lg p-4 mx-2 w-full min-w-[calc(100%/3)] sm:min-w-[calc(100%/4)] transform transition-transform hover:scale-105 hover:shadow-lg hover:z-10"
              >
                <img
                  src={
                    speaker?.image
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${speaker?.image}`
                      : UserIcon
                  }
                  alt={speaker?.speakerName}
                  className="w-24 h-24 rounded-full object-cover mb-4 border-gray-500 border-x-black shadow-xl"
                />
                <h3 className="text-lg font-bold text-gray-800 text-center">
                  {speaker?.speakerName}
                </h3>
                <p
                  className="text-sm text-black truncate overflow-wrap: break-word overflow-para word-wrap: break-word break-words overflow-wrap break-word  w-full text-wrap text-left"
                  dangerouslySetInnerHTML={{
                    __html:
                      expandedIndexes.includes(index) ||
                      speaker?.speakerDescription?.length <= maxLength
                        ? speaker?.speakerDescription
                        : speaker?.speakerDescription?.substring(0, maxLength)
                          ? speaker?.speakerDescription?.substring(
                              0,
                              maxLength
                            ) + "..."
                          : "",
                  }}
                ></p>
                {speaker?.speakerDescription?.length > maxLength && (
                  <button
                    className="text-blue-500 text-sm underline ml-1"
                    onClick={() => toggleExpand(index)}
                  >
                    {expandedIndexes.includes(index) ? "See Less" : "See More"}
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* Navigation Dots */}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-2">
            {speakers.slice(0, 3).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-3 h-3 rounded-full ${
                  currentSlide === index ? "bg-blue-500" : "bg-gray-300"
                }`}
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeakersSection;
